import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerStyles from "../styles/header.module.scss"
import { PiingPartyLogo } from "./piing-logo"

const Header = ({ siteTitle }) => (
  <header className={headerStyles.header}>
    <Link to={'/'}>
      <PiingPartyLogo small />
    </Link>
  </header>
)

export default Header
