import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import footerStyles from "../styles/footer.module.scss"
import { PiingLogo } from "./piing-logo"

const Footer = ({}) => (
  <footer className={footerStyles.footer}>
    <div className={footerStyles.inner}>
        <PiingLogo />
        <div>© {new Date().getFullYear()} <a href='http://piing.events' target={'_blank'} rel={'noopener noreferrer'}>Piing Ltd</a></div>
    </div>
  </footer>
)

export default Footer
